import httpHelper from '@/utils/request'
import { parseSearch } from '@/utils/env'
export const setGoBackUrl = (afterUrl: string, source: string, storeCode: string) => {
  let url = ''
  if (afterUrl) {
    if (afterUrl.indexOf('.benlai.com') > -1) {
      let code = ''
      if (storeCode && afterUrl === 'https://life.benlai.com/') {
        code = 'm/' + storeCode
      }
      url = `${afterUrl}${code}`
    } else if (source === '2') {
      url = `${httpHelper.mRoot}${afterUrl}`
    }
  } else if (source) {
    if (source === '11') {
      url = 'https://h5.benlai.com'
    } else if (source === '15' || source === '16') {
      let code = ''
      if (storeCode) {
        code = '/m/' + storeCode
      }
      url = `https://life.benlai.com${code}`
    } else {
      url = `${httpHelper.mRoot}`
    }
  }
  if (afterUrl && afterUrl.indexOf('passport.benlai.com') > -1 && (afterUrl.indexOf('redirect') > -1 || afterUrl.indexOf('redirect_uri') > -1)) {
    const queryStrings = parseSearch(`?${afterUrl.split('?')[1] || ''}`)
    url = unescape(queryStrings.redirect_uri || queryStrings.redirect || '')
  }
  if (!url) {
    url = `${httpHelper.mRoot}${afterUrl}`
  }
  sessionStorage.setItem('goBackUrl', url)
  console.log('goBackUrl', getGoBackUrl())
}

export const getGoBackUrl = () => {
  return sessionStorage.getItem('goBackUrl') || ''
}

export const removeGoBackUrl = () => {
  sessionStorage.removeItem('goBackUrl')
}
