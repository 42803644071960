import { Base64 } from 'js-base64'

import getCurrentQuery from '@/utils/querystrings'
import branch from '@/configs/branch'
import trunk from '@/configs/trunk'
import prepare from '@/configs/prepare'
import production from '@/configs/production'
export * from '@/configs/arms'

const env = getCurrentQuery('env') || '0'

let envConfigs = production

switch (env) {
  case '1':
    envConfigs = branch
    break
  case '2':
    envConfigs = trunk
    break
  case '3':
    envConfigs = prepare
    break
  default:
    break
}
// console.log('>>', envConfigs)
const clientId = '12c0341e2d9a4f92b5b2cf1968160ccc'
const clientSecret = '150e816f611a4456aa71f4b60dcf84ce'

const basicAuthorization = 'Basic ' + Base64.encode(`${clientId}:${clientSecret}`)
const version = '1.0.0'
let mRoot = 'https://m.benlai.com/'
if (location.href.indexOf('pre-passport.benlai.com') > -1) {
  mRoot = 'https://testm01qa.benlai.com/'
}
const configs = {
  ...envConfigs,
  basicAuthorization,
  version,
  mRoot
}

export default configs
