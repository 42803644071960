export function parseSearch (search: string = '') {
  const result: any = {}
  if (search.indexOf('?') === 0) {
    const str = search.substr(1)
    const strs = str.split('&')
    for (const pair of strs) {
      if (pair.indexOf('=') > 0) {
        result[pair.split('=')[0]] = pair.split('=')[1]
      }
    }
  }
  return result
}

export const getScript = function (url: string, callback: Function) {
  var head = document.getElementsByTagName('head')[0] as any
  var js = document.createElement('script') as any

  js.setAttribute('type', 'text/javascript')
  js.setAttribute('src', url)

  head.appendChild(js)

  // 执行回调
  var callbackFn = function () {
    if (typeof callback === 'function') {
      callback()
    }
  }

  if (document.all) { // IE
    js.onreadystatechange = function () {
      if (js.readyState === 'loaded' || js.readyState === 'complete') {
        callbackFn()
      }
    }
  } else {
    js.onload = function () {
      callbackFn()
    }
  }
}

export default {
  parseSearch,
  getScript
}
