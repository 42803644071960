import { parseSearch } from './utils'

function getCurrentQuery (name: string | undefined) {
  const queryStrings: any = parseSearch(location.search)
  if (!name) {
    return queryStrings
  }
  return queryStrings[name]
}

export default getCurrentQuery
