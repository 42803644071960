let apiRoot = 'https://passport-api.benlai.com/'
let apiv5Root = 'https://app-api.benlai.com/'
let unifyRoot = 'https://unify.benlai.com/'
let authRoot = 'https://auth.benlai.com/'
if (location.href.indexOf('pre-passport.benlai.com') > -1) {
  apiRoot = 'https://pre-passport-api.benlai.com/'
  apiv5Root = 'https://testapi01qa.benlai.com/v5/'
  unifyRoot = 'https://pre-unify.benlai.com/'
  authRoot = 'https://pre-auth.benlai.com/'
}

export default {
  apiRoot,
  apiv5Root,
  unifyRoot,
  authRoot
  // apiRoot: 'http://192.168.60.33:15034/'
  // apiRoot: 'http://10.10.121.152:15034/'
}
