/*
 * @Author: your name
 * @Date: 2022-02-08 13:58:12
 * @LastEditTime: 2022-02-08 13:59:39
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /passport.benlai.com/src/utils/env.ts
 */

export { default as dsBridge } from 'dsbridge'

const w = window as any
const ua = w.navigator.userAgent

export function parseSearch (search: string) {
  const result: any = {}
  if (search.indexOf('?') !== -1) {
    const str = search.substr(1)
    const strs = str.split('&')
    for (const pair of strs) {
      result[pair.split('=')[0]] = unescape(pair.split('=')[1])
    }
  }
  return result
}

export const isWechat = /MicroMessenger/ig.test(ua)
export const isMiniProgram = /miniProgram/ig.test(ua)
export const inBenlaiApp = ua.indexOf('benlai') > -1

export const bridge: any = w.appjsobj || w.androidjsobj
// export const dsBridge = dsBridge

export function compareVersion (cur: string, prev: string) {
  const curArr = cur.split('.')
  const prevArr = prev.split('.')
  for (let i = 0; i < 3; i++) {
    if (curArr[i] > prevArr[i]) {
      return true
    }
    if (curArr[i] < prevArr[i]) {
      return false
    }
  }
  return false
}
