const env = (window as any).benlaiEnv

export const armsPID = 'c7ny4iyr7i@23ef0cceeaac172'

export let armsEnv = 'prod'

switch (env) {
  case 'branch':
    armsEnv = 'local'
    break
  case 'trunk':
    armsEnv = 'daily'
    break
  case 'prepare':
    armsEnv = 'pre'
    break
}
