import Vue from 'vue'
import Vuex from 'vuex'
import { IAddressState } from './modules/address'

Vue.use(Vuex)
export interface IRootState {
  address: IAddressState
}

export default new Vuex.Store<IRootState>({})
